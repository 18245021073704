import React from "react";
import { Navigate, Outlet } from "react-router-dom";


function ProtectedRoute({
  isAuthenticated,
  children,
  adminRoute,
  isAdmin,
  redirect = "/",
  redirectAdmin = "/profile",
}) {
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to={redirect} />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
