import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const userRegistration = createAsyncThunk("userRegistration", async (data) => {
    console.log(data)
    const response = await fetch('https://propertysearch.ai/api/auths/register',data)
    console.log("Register Response", response)
    return response.data
})


export const mobileNoVerification = createAsyncThunk("mobileNoVerification", async (data) => {
   
    const response = await axios('https://propertysearch.ai/api/auths/mobileverification',data)
    console.log("mobileNoVerification Response", response)
    return response.data
})

 const authSlice = createSlice({

    name: "Authentication",

    initialState: {
        Register : [],
        mobileVerification:{},
        status : "idle",
        error : ""
    },

    extraReducers(builder) {

        // user Registration
       
        builder.addCase(userRegistration.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(userRegistration.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.Register = action.payload
            })
            .addCase(userRegistration.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


          //Mobile verification
          
        .addCase(mobileNoVerification.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.mobileVerification = action.payload
        })
         
        }
  });
  
  export default authSlice.reducer;