import React from 'react'
import * as ImIcons from "react-icons/im";

function CommonModal({ Title, Description, setModal, ButtonName, Submit }) {


  return (
    <div
      class="modal fade show"
      style={{ display: "block", paddingRight: "17px" }}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title fw-bold"
              id="exampleModalLongTitle"
            >
              {Title}
            </h5>
            <div onClick={() => setModal(false)}>
              <ImIcons.ImCross />
            </div>
          </div>
          <div class="modal-body">
            <p class="card-text p-4 fs-20">
              {" "}
              {Description}
              {/* For Contact/Book/Bidx you have to pay flat
                                      fee is ₹1000. */}
              {" "}
            </p>
          </div>
          <div class="modal-footer ">

            <button
              onClick={() => Submit()}
              type="button"
              class="btn btn-primary"
            >
              {ButtonName}
            </button>
            <button
              onClick={() => setModal(false)}
              type="button"
              class="btn btn-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonModal