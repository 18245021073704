import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from '../Slices/AuthSlice'
import ProfileSlice from '../Slices/ProfileSlice'
import PropertySlice from '../Slices/PropertySlice'
import AgentSlice from '../Slices/AgentSlice'
import SellerSlice from '../Slices/SellerSlice'
import BuyerSlice from '../Slices/BuyerSlice'
import PaymentSlice from '../Slices/PaymentSlice'
import LawyerSlice from '../Slices/LawyerSlice'
import BankerSlice from '../Slices/BankerSlice'
import MapProperties from "../Slices/MapProperties";
import EvaluatorSlice from '../Slices/EvaluatorSlice'

export const store = configureStore({

  reducer: {
    authentication : AuthSlice,
    userProfile: ProfileSlice,
    userProperty : PropertySlice,
    Agent : AgentSlice,
    Seller : SellerSlice,
    Buyer: BuyerSlice,
    Payment: PaymentSlice,
    Lawyer: LawyerSlice,
    Banker: BankerSlice,
    Evaluator:EvaluatorSlice,
    mapProperties: MapProperties,
  },
})

export default store