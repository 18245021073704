import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";





export const getEvaluatorLeadList  = createAsyncThunk("getEvaluatorLeadList", async ({id,data}) => {
  
  console.log("all Evaluator token",data)
  const response = await axios.get(`https://propertysearch.ai/api/propertyevaluator/getleadlist/${id}`,data);
  console.log(" Evaluator lead list Response", response.data)
  return response.data.data
  
});


export const getEvaluatorLeadDetails  = createAsyncThunk("getEvaluatorLeadDetails", async ({id,data}) => {
  
  console.log("get EvaluatorLeadDetails token",id,data)
  const response = await axios.get(`https://propertysearch.ai/api/propertyevaluator/getleaddetails/${id}`,data);
  console.log(" Evaluator LeadDetails Response", response.data)
  return response.data.data
  
});


export const getAllEvaluators = createAsyncThunk(
  "getAllEvaluators",
  async (token) => {
    console.log("getAllEvaluators agent ", );
    const response = await axios.get(`https://propertysearch.ai/api/users/evaluator`,token);
    // console.log("get Nearby Agents response", response);
    return response.data;
  }
);

export const getMyEvaluator = createAsyncThunk(
  "getMyEvaluator",

  async ({ id, data }) => {
    console.log("get MyEvaluator ", id, data);
    const response = await axios.get(`https://propertysearch.ai/api/propertyevaluator/getevaluatorlistbyuser/${id}`,
      data
    );
    console.log("get MyEvaluator", response.data);
    return response.data.data
  }
);





export const getOrderdetailOfEvaluator = createAsyncThunk("getOrderdetailOfEvaluator", async (orderId) => {
  console.log("Evaluator payment body",orderId)
  const response = await axios(`https://propertysearch.ai/api/propertyevaluator/getCallBack?order_id=${orderId}`)
  console.log("Evaluator Payment  Response", response.data)
  return response.data
})

const EvaluatorSlice = createSlice({
  name: "evaluatorSlice",

  initialState: {
    EvaluatorLeadList:[],
    EvaluatorLeadDetails:{},
    EvaluatorOrderDetails:{},
  
    AllEvaluators: [],
    MyEvaluators: [],
    status: "idle",
    error: "",
  },

  extraReducers(builder) {

     // get evaluators lead list

     builder
     .addCase(getEvaluatorLeadList.pending, (state, action) => {
       state.status = "loading";
     })
     .addCase(getEvaluatorLeadList.fulfilled, (state, action) => {
       state.status = "succeeded";
       state.EvaluatorLeadList = action.payload;
     })
     .addCase(getEvaluatorLeadList.rejected, (state, action) => {
       state.status = "failed";
       state.error = action.error.message;
     });
   

     // get evaluators lead Details

     builder
     .addCase(getEvaluatorLeadDetails.pending, (state, action) => {
       state.status = "loading";
     })
     .addCase(getEvaluatorLeadDetails.fulfilled, (state, action) => {
       state.status = "succeeded";
       state.EvaluatorLeadDetails = action.payload;
     })
     .addCase(getEvaluatorLeadDetails.rejected, (state, action) => {
       state.status = "failed";
       state.error = action.error.message;
     });
   

    // get near by evaluators

    builder
      .addCase(getAllEvaluators.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllEvaluators.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.AllEvaluators = action.payload;
      })
      .addCase(getAllEvaluators.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // get My Evaluators

    builder
      .addCase(getMyEvaluator.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getMyEvaluator.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.MyEvaluators = action.payload;
      })
      .addCase(getMyEvaluator.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

       //Evaluator order details

       builder
       .addCase(getOrderdetailOfEvaluator.pending, (state) => {
         state.status = "loading";
       })
       .addCase(getOrderdetailOfEvaluator.fulfilled, (state, action) => {
         state.status = "succeeded";
         state.EvaluatorOrderDetails = action.payload;
       })
       .addCase(getOrderdetailOfEvaluator.rejected, (state, action) => {
         state.status = "failed";
         state.error = action.error.message;
       });


  },
});



export default EvaluatorSlice.reducer;
