import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";




export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async ({ id, data }) => {
    console.log("slice", id);
    const response = await axios.get(
      `https://propertysearch.ai/api/profile/${id}`,
      data
    );
    console.log("User Response", response);
    return response.data;
  }
);

//

export const getUserKycData = createAsyncThunk(
  "getUserKycData",
  async ({ id, data }) => {
    console.log("slice img", id, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/profile/user/getkyc/${id}`,
      data
    );
    console.log("User kyc Response", response);
    return response.data;
  }
);



export const getUserBankDetails = createAsyncThunk(
  "getUserBankDetails",
  async ({ id, data }) => {
    console.log("slice img", id, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/profile/finduserbankdetails/${id}`,
      data
    );
    console.log("User BankDetails Response", response.data);
    return response.data.data;
  }
);

export const getUserBankDetailsById = createAsyncThunk(
  "getUserBankDetailsById",
  async ({ Bankid, data }) => {
    console.log("slice img", Bankid, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/profile/getuserbankdetails/${Bankid}`,
      data
    );
    console.log("User BankAccountDetails Response", response.data);
    return response.data.data;
  }
);


export const changePassword = createAsyncThunk(
  "changePassword",
  async (data) => {
    console.log("slice chhange pass", data);
    const response = await fetch(
      `https://propertysearch.ai/api/profile/changepassword`,
      data
    );
    console.log("change paasss Response", response);
    return response.data;
  }
);



export const getUserNotification = createAsyncThunk(
  "getUserNotification",
  async ({ id, data }) => {
    console.log("slice notification", id, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/propertyverification/getnotification/${id}`,
      data
    );
    console.log("user notification Response", response.data.data)
    return response.data.data
  }
);



export const getUserSubscriptionPackages = createAsyncThunk(

  "getUserSubscriptionPackages",
  async ({ role, data }) => {
    console.log("ddddfdf", role, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/subscriptions/${role}`,
      data
    );
    console.log("subscription pscksgesd", response.data.data);
    return response.data.data;
  }
);



export const getUserBuyPackages = createAsyncThunk(

  "getUserBuyPackages",
  async ({ userId, data }) => {
    console.log("ddddfdf", userId, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/propertysubscription/userpaymenthistory/${userId}`,
      data
    );
    console.log("Buyed subscription pscksgesd", response.data.data);
    return response.data.data;
  }
);


export const getSubscriptionPackageDetails = createAsyncThunk(
  " getSubscriptionPackageDetails",
  async ({ packageid, data }) => {
    console.log("subscription pscksgesfgbgbd", packageid);
    const response = await axios.get(
      `https://propertysearch.ai/api/subscriptions/subscriptiondetails/${packageid}`,
      data
    )

    return response.data.data;
  }
);


export const getAdharVerification = createAsyncThunk(
  "getAdharVerification",
  async (data) => {
    console.log("slice get AdharVerification", data);
    const response = await axios(
      `https://sandbox.cashfree.com/verification/offline-aadhaar/otp`,
      data
    );
    console.log("get AdharVerification ", response.data);
    return response.data
  }
);

export const getKycVerificationStatus = createAsyncThunk(
  "getKycVerificationStatus",
  async ({ id, data }) => {
    console.log("slice get KycVerificationStatus", id, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/profile/verificationstatus/${id}`,
      data
    );
    return response.data;
  }
);



export const getSubscriptionOrderDetails = createAsyncThunk("getSubscriptionOrderDetails", async ({id,data}) => {
  console.log("getSubscriptionOrderDetails payment body",id,data)
  const response = await axios(`https://propertysearch.ai/api/propertysubscription/getCallBack?order_id=${id}`,data)
  console.log("getSubscriptionOrderDetails Payment  Response", response.data)
  return response.data
})


export const getUserDashboardContent = createAsyncThunk(
  "getUserDashboardContent",
  async ({ id, data }) => {
    console.log("slice getUserDashboardContent", id, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/dashboard/userdashboard/${id}`,
      data
    );
    console.log('getUserDashboardContent',response.data)
    return response.data;
  }
);


const profileSlice = createSlice({
  name: "profileentication",

  initialState: {
    profile: {},
    status: "idle",
    KycData: {},
    Notification: [],
    SubscriptionPackageDetails: {},
    SubScriptionOrderDetails:{},
    userDashboardContent:[],
    SubscriptionPackages: [],
    BuySubscriptionPackages: [],
    adhaarVerification: {},
    UserBankDetails:[],
    UserBankAccountDetails:{},
    verificationStatus: {},
    subscriptionRequestModal: true,
    passStatus: "",
    error: "",
  },


  reducers: {
    subscriptionRequestToggle(state, action) {
      state.subscriptionRequestModal = action.payload;
    },
  },

  extraReducers(builder) {
    // get user profile data

    builder
      .addCase(getUserProfile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.profile = action.payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // user change password

    builder
      .addCase(changePassword.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.changePassword = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // get user notification

    builder
      .addCase(getUserNotification.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserNotification.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.Notification = action.payload;
      })
      .addCase(getUserNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // get User Subscription Packages

    builder
      .addCase(getUserSubscriptionPackages.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserSubscriptionPackages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.SubscriptionPackages = action.payload;
      })
      .addCase(getUserSubscriptionPackages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })


      // get User Subscription Packages

    builder
    .addCase(getUserBuyPackages.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getUserBuyPackages.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.BuySubscriptionPackages = action.payload;
    })
    .addCase(getUserBuyPackages.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })

      // imgae Upload

      .addCase(getUserKycData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.KycData = action.payload;
      });

         // user Dashboard Content

    builder
    .addCase(getUserDashboardContent.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getUserDashboardContent.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.userDashboardContent = action.payload;
    })
    .addCase(getUserDashboardContent.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });


       // get User BankDetails

       builder
       .addCase(getUserBankDetails.pending, (state, action) => {
         state.status = "loading";
       })
       .addCase(getUserBankDetails.fulfilled, (state, action) => {
         state.status = "succeeded";
         state.UserBankDetails = action.payload;
       })
       .addCase(getUserBankDetails.rejected, (state, action) => {
         state.status = "failed";
         state.error = action.error.message;
       });

       // get User BankAccountDetails

       builder
       .addCase(getUserBankDetailsById.pending, (state, action) => {
         state.status = "loading";
       })
       .addCase(getUserBankDetailsById.fulfilled, (state, action) => {
         state.status = "succeeded";
         state.UserBankAccountDetails = action.payload;
       })
       .addCase(getUserBankDetailsById.rejected, (state, action) => {
         state.status = "failed";
         state.error = action.error.message;
       });


    // Adhar  verification

    builder
      .addCase(getAdharVerification.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAdharVerification.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adhaarVerification = action.payload;
      })
      .addCase(getAdharVerification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    builder
      .addCase(getKycVerificationStatus.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getKycVerificationStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.verificationStatus = action.payload;
      })
      .addCase(getKycVerificationStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })


      builder
      .addCase(getSubscriptionOrderDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubscriptionOrderDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.SubScriptionOrderDetails = action.payload;
      })
      .addCase(getSubscriptionOrderDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

      // get subscription details

      builder
      .addCase(getSubscriptionPackageDetails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSubscriptionPackageDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.SubscriptionPackageDetails = action.payload;
      })
      .addCase(getSubscriptionPackageDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});


export const { subscriptionRequestToggle } = profileSlice.actions;
export default profileSlice.reducer;
