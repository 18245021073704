import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Common/Sidebar";
import ProtectedRoute from "../Router/ProtectedRoutes";
import Navbar from "../Common/Navbar";
import Footer from "../Common/footer";


function DashboardLayout({ children }) {

  return (
    <div>
   
      <Navbar /> 
      <Sidebar />
      {children}
      {/* <Footer /> */}
      <ProtectedRoute />

    </div>
  );
}

export default DashboardLayout;
