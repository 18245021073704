import React, { useState } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import * as BiIcons from "react-icons/bi";
import * as CiIcons from "react-icons/ci";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as FiIcons from "react-icons/fi";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineAddHomeWork } from "react-icons/md";
import { MdOutlineContactEmergency } from "react-icons/md";
import { RiBook3Line } from "react-icons/ri";
import { MdPayment } from "react-icons/md";

import Login from "../Auth/login";
import { userRole } from "../JsonData/json";
import Footer from "./footer";


const sidebarData = {

  
  user: [
    {
      id: 1,
      name: "Dashboard",
      path: "/UserDashboard",
      icon: <FiIcons.FiSettings />,
    },
    {
      id: 2,
      name: "Property Analysis",
      path: "/PropertyAnalysis",
      icon: <FiIcons.FiSettings />,
    },
    {
      id: 3,
      name: "My Properties",
      path: "/OwnerPropertiesScreen",
      icon: <MdOutlineHomeWork />,
    },
    {
      id: 4,
      name: "My Shortlists",
      path: "/ShortlistedProperty",
      icon: <MdOutlineAddHomeWork />,
    },
    {
      id: 5,
      name: "Owner's You Contacted",
      path: "/InterestedProperties",
      icon: <MdOutlineContactEmergency />,
    },
    {
      id: 6,
      name: "My Bids",
      path: "/BidProperties",
      icon: <BiIcons.BiHomeHeart />,
    },
    {
      id: 7,
      name: "My Bookings",
      path: "/BookedProperties",
      icon: <RiBook3Line />,
    },
    {
      id: 8,
      name: "Payments",
      path: "/PaymentHistory",
      icon: <MdPayment />,
    },
    {
      id: 9,
      name: "Hire Agent",
      path: "/HireAgent",
      icon: <BiIcons.BiHomeHeart />,
    },
    {
      id: 10,
      name: "Hire Lawyer",
      path: "/HireLawyer",
      icon: <BiIcons.BiHomeHeart />,
    },
    {
      id: 11,
      name: "Hire Property Evaluators",
      path: "/PropertyEvaluators",
      icon: <BiIcons.BiHomeHeart />,
    },
    {
      id: 12,
      name: "My Packages",
      path: "/MyPackages",
      icon: <BiIcons.BiFoodMenu />,
    },
  ],

  agent: [
    {
      id: 1,
      name: "Dashboard",
      path: "/UserDashboard",
      icon: <FiIcons.FiSettings />,
    },
    {
      id: 2,
      name: "Leads List",
      path: "/AgentLeadList",
      icon: <AiIcons.AiOutlineTeam />,
    },
    {
      id: 3,
      name: "Assigned Properties",
      path: "/AssignedProperties",
      icon: <BsIcons.BsHouseAdd />,
    },
    {
      id: 4,
      name: "Booked Properties",
      path: "/BookedPropertiesByAgent",
      icon: <BsIcons.BsHouseAdd />,
    },
    {
      id: 5,
      name: "Property Visitors",
      path: "/PropertyVisitors",
      icon: <BsIcons.BsHouseAdd />,
    },
    {
      id: 6,
      name: "My Packages",
      path: "/MyPackages",
      icon: <BiIcons.BiFoodMenu />,
    },
  ],
  lawyer: [
    {
      id: 1,
      name: "Dashboard",
      path: "/UserDashboard",
      icon: <FiIcons.FiSettings />,
    },
    {
      id: 2,
      name: "Leads List",
      path: "/LawyerLeadList",
      icon: <AiIcons.AiOutlineTeam />,
    },
    {
      id: 3,
      name: "My Packages",
      path: "/MyPackages",
      icon: <BiIcons.BiFoodMenu />,
    },
  ],
  evaluator: [
    {
      id: 1,
      name: "Dashboard",
      path: "/UserDashboard",
      icon: <FiIcons.FiSettings />,
    },
    {
      id: 2,
      name: "Leads List",
      path: "/EvaluatorLeadList",
      icon: <AiIcons.AiOutlineTeam />,
    },
    {
      id: 3,
      name: "My Packages",
      path: "/MyPackages",
      icon: <BiIcons.BiFoodMenu />,
    },
  ],
};


function Sidebar() {

  const navigate = useNavigate();

  const [subnav, setSubnav] = useState(false);
  const [ActiveTab,setActiveTab] = useState()
  const UserRole = localStorage.getItem("userRole");

  const sidebar_List = sidebarData[UserRole] || [];
  const email = sessionStorage.getItem("email");

  console.log("I am user role ", UserRole);
  const [propertyVerified, setPropertyVerified] = useState(true);

  const handleRedirectClick = () => {
    const redirectURL = `https://propertysearch.ai/login/validate_login?email=${email}`;
    window.location.href = redirectURL;
  };

  const collapse = () => {
    if (subnav == false) {
      setSubnav(true);
    } else {
      setSubnav(false);
    }
  };


  const logout = () => {
    localStorage.clear("userName");
    localStorage.clear("userRole");
    localStorage.clear("userId");
    navigate("/");
  };


  console.log("userRole in Login : ", UserRole);
  const [isSidebarOpen, setSidebarOpen] = useState(false);


  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };


  return (
    <>
      <div class="wrapper dashboard-wrapper ">
        <div class="d-flex flex-wrap flex-nowrap">
          <div className={`sidebar ${isSidebarOpen ? 'open' : '', 'd-lg-none'}`} 
             style={{ zIndex: '1000', width: isSidebarOpen ? '70%' : '0%' }}
            >
            <ul class="list-group list-group-flush w-100" >
              <li class="list-group-item pt-6 pb-4 px-0">
                <ul class="list-group list-group-no-border rounded-lg">
                 
                  {sidebar_List.map((item) => {
                    return (
                      <>
                        <li onClick={()=>setActiveTab(item.id)} className={`list-group-item${ActiveTab == item.id ? '-active' : ''} py-2 sidebar-item display-flex`}>
                          <Link
                            to={item.path}
                            class="text-heading lh-1 sidebar-link"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span class="sidebar-item-icon d-inline-block mr-2 text-muted fs-20 fs-20">
                              <svg class="icon icon-add-new">
                                {item.icon}
                              </svg>
                            </span>
                            <span class={ActiveTab == item.id ? "sidebar-item-text-active": "sidebar-item-text" }>
                              {item.name}
                            </span>
                          </Link>
                        </li>
                      </>
                    );
                  })}

                  {UserRole == "agent" ?
                    <li class="list-group-item  py-2 sidebar-item">
                      <li
                        onClick={() => handleRedirectClick()}
                        class="text-heading lh-1 sidebar-link d-flex align-items-center"
                      >
                        <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg class="icon icon-my-package">
                            <BiIcons.BiFoodMenu />
                          </svg>
                        </span>
                        <span class="sidebar-item-text">
                          Training
                        </span>
                      </li>
                    </li>
                    :
                    null
                  }
                  
                </ul>
              </li>
              <li class="list-group-item pt-6 pb-4">
                <ul class="list-group list-group-no-border rounded-lg"></ul>
              </li>
            </ul>
            <button onClick={()=>toggleSidebar()} className="hamburger">
           ⟩
            </button>
          </div>
          <div class="db-sidebar bg-white d-none d-lg-flex">
            <nav class="navbar navbar-expand-lg navbar-light d-block px-0 header-sticky dashboard-nav py-0">
              <div class="sticky-area py-0">
                <div
                  class="collapse navbar-collapse bg-white mt-3"
                  id="primaryMenuSidebar"
                >
                  <ul class="list-group list-group-flush w-100">
                    <li class="list-group-item pt-6 pb-4 px-0">
                      <ul class="list-group list-group-no-border rounded-lg">

                        {sidebar_List.map((item) => {
                          return (
                            <>
                             <li onClick={()=>setActiveTab(item.id)} className={`${ActiveTab == item.id ? 'list-group-item-active' :' list-group-item'} py-2 sidebar-item display-flex`}>
                                <Link
                                  to={item.path}
                                  class="text-heading lh-1 sidebar-link"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span class="sidebar-item-icon d-inline-block mr-2 text-muted fs-20 fs-20">
                                    <svg class="icon icon-add-new">
                                      {item.icon}
                                    </svg>
                                  </span>
                                  <span class={ActiveTab == item.id ? "sidebar-item-text-active": "sidebar-item-text" }>
                                    {item.name}
                                  </span>
                                </Link>
                              </li>
                            </>
                          );
                        })}


                        {UserRole == "agent" ?
                          <li class="list-group-item  py-2 sidebar-item">
                            <li
                              onClick={() => handleRedirectClick()}
                              class="text-heading lh-1 sidebar-link d-flex align-items-center"
                            >
                              <span class="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                                <svg class="icon icon-my-package">
                                  <BiIcons.BiFoodMenu />
                                </svg>
                              </span>
                              <span class="sidebar-item-text">
                                Training
                              </span>
                            </li>
                          </li>
                          :
                          null
                        }
                      </ul>
                    </li>
                    <li class="list-group-item pt-6 pb-4">
                      <ul class="list-group list-group-no-border rounded-lg"></ul>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
