import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



export const FormatPriceInComma = (Price) => {
    console.log("BookingAmount",Price)

    if (Price === "") {
      return "";
    }
    return Price?.toLocaleString();
  };


export const FormatPrice = (propertyPrice) => {
    console.log("propertyPrice",propertyPrice)

    if (propertyPrice === "") {
      return "";
    }
    const numericValue = parseFloat(propertyPrice?.replace(/,/g, ""));
    return numericValue.toLocaleString("en-IN");
};


export const getUserProperty = createAsyncThunk("getUserProperty", async ({id,data,page}) => {
    console.log("slice", id)
    const response = await  axios.get(`https://propertysearch.ai/api/Property/findByUser/${id}?page=${page}`,data)
    console.log("User Property Response", response)
    return response.data
})
  

export const getAllFlats = createAsyncThunk("getAllFlats", async (data) => {
    console.log("slice", data)
    const response = await  axios.post(`https://propertysearch.ai/api/property/filterproperty`,data)
    console.log("User All flats Response", response)
    return response.data
})


export const getNearbyProperties = createAsyncThunk("getNearbyProperties", async ({param1,param2,param3,param4}) => {

    console.log("getNearbyProperties slice", param1,param2,param3)
      const response = await  axios.get(`https://propertysearch.ai/api/property/nearbyuser/${param1},${param2}/${param4}`,param3)
    //   const response = await  axios.get(` https://propertysearch.ai/api/property/nearbyuser/18.5282286,73.7778077`,param3)
    console.log("Nearby Properties Response", response.data)
    return response.data

}) 


export const getFavoriteProperties = createAsyncThunk("getFavoriteProperties", async ({id,data}) => {
    console.log("getFavoriteProperties slice", id,data)
   
    const response = await  axios.get(`https://propertysearch.ai/api/property/findbyfavourite/${id}`,data)
    // const response = await  axios.get(` https://propertysearch.ai/api/property/nearbyuser/37.7749,-122.4194`,param3)
    console.log("favorite Properties Response", response.data.data)
    return response.data.data
}) 


export const getPropertyByid = createAsyncThunk("getPropertyByid", async ({id,userId,data}) => {
    console.log('getPropertyByid',id,userId,data)
    const response = await  axios.get(`https://propertysearch.ai/api/property/findbyuserid/${id}/${userId}`,data)
    console.log('getPropertyByid',response)
    return response.data
}) 

//  Recommended Property

export const getRecommendedProperty = createAsyncThunk("getRecommendedProperty", async (data) => {


    console.log("getRecommendedProperty slice", data)
    const response = await  axios(`https://propertysearch.ai/api/property/recommendproperty`,data)
    console.log("getRecommendedProperty Response", response)
    return response.data
}) 


export const getAllProperties = createAsyncThunk("getAllProperties", async ({data,page}) => {
    console.log("get all properties slice", data)
    const response = await  axios(`https://propertysearch.ai/api/property/propertylist?page=${page}`,data)
    console.log("getRecommendedProperty Response", response.data)
    return response.data.data
})  


export const getEditProperties = createAsyncThunk("getEditProperties", async ({id,data}) => {
    console.log("get edit properties slice", id,data)
    const response = await  axios.get(`https://propertysearch.ai/api/property/${id}`,data)
    console.log("getEditProperties Response", response.data)
    return response.data
}) 


export const getOfferedProperties = createAsyncThunk("getOfferedProperties", async ({id,data}) => {
    console.log("get Offered Properties slice", data)
    const response = await  axios(`https://propertysearch.ai/api/property/getofferproperty/${id}`,data)
    console.log("get Offered Properties Response", response.data)
    return response.data.data
}) 


const PropertySlice = createSlice({

    name: "Propertyentication",

    initialState: {
        Properties : [],
        AllFlats:[],
        NearbyProperties:[],
        favoriteProperties:[],
        OfferedProperties:[],
        EditProperties:{},
        singleProperty:{},
        status:'',
        AllProperties:[]
    },

    extraReducers(builder) {
        
        // get user Property data
       
        builder.addCase(getUserProperty.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getUserProperty.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.Properties= action.payload
            })
            .addCase(getUserProperty.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


        // get  all  Flats

            builder.addCase(getAllFlats.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getAllFlats.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.AllFlats= action.payload
            })
            .addCase(getAllFlats.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })

           // get all nearby properties 
           builder.addCase(getNearbyProperties.pending, (state, action) => {
            state.status = "loading"
         })

        .addCase(getNearbyProperties.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.NearbyProperties= action.payload
        })

        .addCase(getNearbyProperties.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })

        // Get Favorite Poperties

        builder.addCase(getFavoriteProperties.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getFavoriteProperties.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.favoriteProperties= action.payload
        })
        .addCase(getFavoriteProperties.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })

          // Get Poperties by id

          builder.addCase(getPropertyByid.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getPropertyByid.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.singleProperty= action.payload
        })
        .addCase(getPropertyByid.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })


           // Get recommended Poperties 

           builder.addCase(getRecommendedProperty.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getRecommendedProperty.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.recommendedProperty= action.payload
        })
        .addCase(getRecommendedProperty.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })


        // All propertie  on dashboard
        
        builder.addCase(getAllProperties.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllProperties.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.AllProperties= action.payload
        }) 
        .addCase(getAllProperties.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })


         //  Edit properties
        
         builder.addCase(getEditProperties.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getEditProperties.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.EditProperties= action.payload
        }) 
        .addCase(getEditProperties.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })


         //  Edit properties
        
         builder.addCase(getOfferedProperties.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getOfferedProperties.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.OfferedProperties= action.payload
        }) 
        .addCase(getOfferedProperties.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })
        }

  });
  
  export default PropertySlice.reducer;