export const PropertyType = [
  { label: "Flats", value: "1" },
  { label: "Houses", value: "2" },
  { label: "Studio Apartments", value: "3" },
  { label: "Villas", value: "4" },
  { label: "Plots", value: "5" },
  { label: "Guest House", value: "6" },
  { label: " Penthouse", value: "7" },
];

export const Commerical = [
  { label: "Office Space", value: "1" },
  { label: "Office in IT park/SEZ", value: "2" },
  { label: "Shop", value: "3" },
  { label: "Showroom", value: "4" },
  { label: "Commerical Land", value: "5" },
  { label: "Warehouse/ Godown", value: "6" },
  { label: "Industrial Land", value: "7" },
  { label: "Industrial Building", value: "8" },
  { label: "Industrial Shed", value: "9" },
  { label: "Agricultural Land", value: "9" },
];

export const SaleType = [
  { label: "ReSale Property", value: "ReSale Property" },
  { label: "New Property", value: "New Property" },
];

export const OwnerShip = [
  { label: "Individual", value: "Individual" },
  { label: "others", value: "others" },
  { label: "A Broker/Agent", value: "A Broker/Agent" },
  { label: "A Builder/Promotor", value: "A Builder/Promotor" },
];

export const Availability = [
  { value: "Under Construction", label: "Under Construction" },
  { value: "Ready To Move", label: "Ready To Move" },
  { value: "UpComing", label: "Upcoming" },
];

export const Facilities = [
  { id: 1, label: "Temple" },
  { id: 2, label: "School" },
  { id: 3, label: "Hospital" },
  { id: 4, label: "Park" },
  { id: 5, label: "Market" },
  { id: 6, label: "Gym" },
  { id: 7, label: "Restaurant" },
  { id: 8, label: "Cinema" },
  { id: 9, label: "Library" },
  { id: 10, label: "Public Transport" },
  { id: 11, label: "Playground" },
  { id: 12, label: "Shopping Mall" },
  { id: 13, label: "Police Station" },
  { id: 14, label: "Fire Station" },
];

export const No_OF_Floor = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10+", value: 10 },
];

export const Property_On_Floor = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10+", value: 10 },
];

export const BedRooms = [
  { value: "1 RK", label: "1 RK" },
  { value: "1 BHK", label: "1 BHK" },
  { value: "2 BHK", label: "2 BHK" },
  { value: "3 BHK", label: "3 BHK" },
  { value: "4 BHK", label: "4 BHK" },
  { value: "5 BHK", label: "5 BHK" },
  { value: "6 BHK", label: "6 BHK" },
  { value: "7 BHK", label: "7 BHK" },
  { value: "8 BHK", label: "8 BHK" },
  { value: "9 BHK", label: "9 BHK" },
  { value: "10+ BHK", label: "10+ BHK" },
];

export const Bath_Rooms = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
];

export const Balconies = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10+", value: 10 },
];

export const status = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];

export const Area_Unit = [
  { label: "Area Unit", value: 1 },
  { label: "Sq.ft", value: 2 },
  { label: "Cent", value: 3 },
  { label: "Sq.Yards", value: 4 },
  { label: "Area", value: 5 },
  { label: "Acre", value: 6 },
  { label: "Sq.Meter", value: 7 },

  // label: 'Bigha',
  // value: 'Bigha',
  // label: 'Hectares',
  // value: 'Hectares',
  // label: 'Guntha',
  // value: 'Guntha',
  // label: 'Marla',
  // value: 'Marla',
  // label: 'Dismil',
  // value: 'Dismil',
  // label: 'Biswa',
  // value: 'Biswa',
  // label: 'Katha',
  // value: 'Katha',
];

export const AmenitiesData = [
  { id: 1, amenitiesName: "Air Conditioning" },
  { id: 2, amenitiesName: "Heating System" },
  { id: 3, amenitiesName: "Swimming Pool" },
  { id: 4, amenitiesName: "Fitness Center/Gym" },
  { id: 5, amenitiesName: "Spa/Hot Tub" },
  { id: 6, amenitiesName: "Tennis Court" },
  { id: 7, amenitiesName: "Playground" },
  { id: 8, amenitiesName: "Garden/Patio" },
  { id: 9, amenitiesName: "Balcony/Terrace" },
  { id: 10, amenitiesName: "Garage/Parking Space" },
  { id: 11, amenitiesName: "Security System" },
  { id: 12, amenitiesName: "Elevator" },
  { id: 13, amenitiesName: "Laundry Facilities" },
  { id: 14, amenitiesName: "Pet-Friendly Facilities" },
  { id: 15, amenitiesName: "Walk-in Closets" },
  { id: 16, amenitiesName: "High-Speed Internet/Wi-Fi" },
  { id: 17, amenitiesName: "Cable/Satellite TV" },
  { id: 18, amenitiesName: "Fireplace" },
  { id: 19, amenitiesName: "Dishwasher" },
  { id: 20, amenitiesName: "Refrigerator" },
  { id: 21, amenitiesName: "Microwave" },
  { id: 22, amenitiesName: "Oven/Stove" },
  { id: 23, amenitiesName: "Washer and Dryer" },
  { id: 24, amenitiesName: "Recycling Facilities" },
  { id: 25, amenitiesName: "Wheelchair Accessibility" },
  { id: 26, amenitiesName: "24/7 Maintenance Service" },
  { id: 27, amenitiesName: "On-Site Management" },
  { id: 28, amenitiesName: "Gated Community" },
  { id: 29, amenitiesName: "Clubhouse" },
  { id: 30, amenitiesName: "BBQ/Grill Area" },
];

export const Room_Facility = [
  {
    value: 1,
    label: "Ac",
  },
  {
    value: 2,
    label: "Fan",
  },
  {
    value: 3,
    label: "Room heater",
  },
  {
    value: 4,
    label: "Locker",
  },
  {
    value: 5,
    label: "Study Table",
  },
  {
    value: 6,
    label: "Laundry",
  },
  {
    value: 7,
    label: "Cable TV",
  },
  {
    value: 8,
    label: "Internate",
  },
  {
    value: 6,
    label: "Hot Water",
  },
];

export const AvailableFor = [
  { label: "Girls", value: "Girls" },
  { label: "Working Women", value: "Working Women" },
  { label: "Boys", value: "Boys" },
  { label: "Working men", value: "Single" },
];

export const Room_Type = [
  { label: "Single", value: "Single" },
  { label: "Double", value: "Double" },
  { label: "Dormitory", value: "Dormitory" },
];

export const Food = [
  { label: "With Meals", value: "With Meals" },
  { label: "Without Meals", value: "Without Meals" },
];

export const Furnishing = [
  { label: "Furnished", value: "Furnished" },
  { label: "UnFurnished", value: "UnFurnished" },
  { label: "Semi-Furnished", value: "Semi-Furnished" },
];

export const Facing = [
  { label: "East", value: "East" },
  { label: "West", value: "West" },
  { label: "South", value: "South" },
  { label: "North", value: "North" },
  { label: "South East", value: "South East" },
  { label: "South West", value: "South West" },
  { label: "North East", value: "North East" },
  { label: "North West", value: "North West" },
];

export const Type_OF_Floor = [
  { label: "Bamboo", value: "Bamboo" },
  { label: "Brick", value: "Brick" },
  { label: "Carpet", value: "Carpet" },
  { label: "Concrete", value: "Concrete" },
  { label: "Tile", value: "Tile" },
  { label: "Marble", value: "Marble" },
  { label: "HardWood", value: "HardWood" },
  { label: "Laminate", value: "Laminate" },
];

export const Age_OF_Property = [
  { label: "Under Construction", value: "UnderConstruction" },
  // {label: 'New Construction',value: 'NewConstruction' },
  { label: "0-5 Years", value: "0-5 Years" },
  { label: "5-10 Years", value: "5-10 Years" },
  { label: "10-15 Years", value: "10-15 Years" },
  { label: "15-20 Years", value: "15-20 Years" },
  { label: "Above 20 Years", value: "Above 20 Years" },
];

export const Other_Rooms = [
  { label: "Pooja Room", value: "Pooja Room" },
  { label: "Servent Room", value: "Servent Room" },
  { label: "Study Room", value: "Study Room" },
  { label: "other Room", value: "other Room" },
];

export const userRole = [
  { label: "Buyer/Seller", value: "user" },
  { label: "Agent", value: "agent" },
  { label: "Lawyer", value: "lawyer" },
  { label: "Property Evaluator", value: "evaluator" },
];

export const bankWebsite = [
  { label: "@kotak.com", value: "@kotak.com" },
  { label: "@sbi.co.in", value: "@sbi.co.in" },
  { label: "@hdfcbank.com", value: "@hdfcbank.com" },
  { label: "@icicibank.com", value: "@icicibank.com" },
  { label: "@pnbindia.in", value: "@pnbindia.in" },
  { label: "@axisbank.com", value: "@axisbank.com" },
  { label: "@canarabank.in", value: "@canarabank.in" },
  { label: "@ bankofbaroda.in", value: "@ bankofbaroda.in" },
  { label: "@kotak.com", value: "@kotak.com" },
  { label: "@indusind.com", value: "@indusind.com" },
];

export const bankName = [
  { label: "kotak", value: "@kotak" },
  { label: "sbi", value: "@sbi" },
  { label: "hdfcbank", value: "hdfcbank" },
  { label: "icicibank", value: "icicibank" },
  { label: "pnbindia", value: "@pnbindia" },
  { label: "axisbank", value: "axisbank" },
  { label: "canarabank", value: "canarabank" },
  { label: "bankofbaroda", value: "bankofbaroda" },
  { label: "kotak", value: "@kotak" },
  { label: "indusind", value: "indusind" },
];
