import { useEffect, useRef, useState } from "react";
// import PropertyList from "./propertyList";
import { useNavigate } from "react-router-dom";
import { getNearbyProperties } from "../../Redux/Slices/PropertySlice";
import NearbyProperties from "../Properties/NearbyProperties";
import { useDispatch, useSelector } from "react-redux/es";

const MapwithList = () => {

  const mapRef = useRef(null);
  const markers = useRef([]);
  const infoWindows = useRef([]);
  const Properties = useSelector((state) => state.userProperty.NearbyProperties);

  const [currentZoom, setCurrentZoom] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  const useCurrentLocation = sessionStorage.getItem("userLocation");
  const location = JSON.parse(useCurrentLocation);

  console.log("Near by Properties", location);

  useEffect(() => {
    const userData = {
      headers: {
        authorization: token,
      },
    };
    dispatch(
      getNearbyProperties({
        param1: location?.latitude,
        param2: location?.longitude,
        param3: userData,
        param4: userId,
      })
    );
  }, [useCurrentLocation, token]);

  useEffect(() => {
    if (!mapRef.current) return;

    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 18.5204, lng: 73.8567 },
      zoom: 10,
    });

    map.addListener("zoom_changed", () => {
      setCurrentZoom(map.getZoom());
    });

    markers.current = Properties?.map((property, index) => {
      const marker = new window.google.maps.Marker({
        position: {
          lat: property?.locationData?.latitude,
          lng: property?.locationData?.longitude,
        },
        map,
        title: property?.propertydata?.title,
      });


      marker.addListener("click", () => {
        // Handle marker click event
        console.log("Marker clicked!", property.name);
        navigate(`/ViewPropertyDetails/${index}`);
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div>
                         <p>Name: ${property?.propertydata?.title}</p>
                         <p>Price: ${property?.propertydata?.price}</p>
                       
                    </div>`,
      });

      marker.addListener("mouseover", () => {
        infoWindow.open(map, marker);
      });

      marker.addListener("mouseout", () => {
        infoWindow.close();
      });

      infoWindows.current.push(infoWindow);

      return marker;
    });

    return () => {
      markers.current.forEach((marker) => {
        marker.setMap(null);
      });
      infoWindows.current.forEach((infoWindow) => {
        infoWindow.close();
      });
    };
  }, [Properties]);

  // const filteredProperties = Properties?.filter(
  //     property => property.zoomLevel <= currentZoom
  // );

  return (
    <>
      <div class="col-sm-7 col-xxl-3 mb-6">
        <div
          ref={mapRef}
          style={{ width: "100%", height: "400px", marginTop: "20px" }}
        ></div>
      </div>
      {Properties.length == 0 ? (
        <div class="column-content col-sm-4 col-xxl-3 ">
          <p class="mt-18 text-center fs-25">
            For this location data not available
          </p>
        </div>
      ) : (
        <NearbyProperties filteredProperties={Properties} />
      )}
    </>
  );
};

export default MapwithList;
