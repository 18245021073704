import "./Assets/css/themes.css";
import "./Assets/css/docs.css";
import "./Assets/style.css";
// import "bootstrap/dist/css/bootstrap.css";
import { useGeolocated } from "react-geolocated";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Router from "./Router/Routing";
import { useState } from "react";
import { useEffect } from "react";
import VarificationModal from "./Common/VarificationModal";
import { useSelector, useDispatch } from "react-redux";
import { subscriptionRequestToggle } from "./Redux/Slices/ProfileSlice";


function App() {

  const dispatch = useDispatch();
  const [userLocation, setUserLocation] = useState(null);

  const VerificationStatus = useSelector(
    (state) => state.userProfile.verificationStatus.verificationstatus
  );

  const subscriptionRequestModal = useSelector(
    (state) => state.userProfile.subscriptionRequestModal
  );

  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay color with some transparency
    display: subscriptionRequestModal ? "block" : "none", // Show/hide based on the modal state
    zIndex: 999, // Make sure it's above other elements
  };


  const token = localStorage.getItem("token");
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  console.log("coords", coords?.GeolocationCoordinates?.latitude);
  console.log("isGeolocationAvailable" , isGeolocationAvailable)
  useEffect(() => {
    if (coords) {
      const { latitude, longitude } = coords;
      setUserLocation({ latitude, longitude });
      sessionStorage.setItem(
        "userLocation",
        JSON.stringify({ latitude, longitude })
      );
    }
  }, [coords]);

  
  return (
    <>
      <div>
        <BrowserRouter>
          {/* {subscriptionRequestModal && token && (
            <div>
              <VarificationModal />
            </div>
          )} */}
          <Router />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
