import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getRequestedProperties = createAsyncThunk(
  "getRequestedProperties",
  async ({ id, data }) => {
    console.log("get Requested Properties", id, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/agents/requestinterestlist/${id}`,
      data
    );
    console.log("get Requested Properties response", response);
    return response.data;
  }
);


export const getNearbyAgents = createAsyncThunk(
  "getNearbyAgents",
  async ({ param1, param2, param3,param4 }) => {
    console.log("get nearby agent ", param1, param2, param3,param4);
    const response = await axios.get(
      `https://propertysearch.ai/api/agents/nearbyagent/${param1},${param2}/${param4}`,
      param3
    );
    // console.log("get Nearby Agents response", response);
    return response.data;
  }
);


export const getMyAgents = createAsyncThunk(

  "getMyAgents",

  async ({ id, data }) => {
    console.log("get my agent ", id, data);
    const response = await axios.get(`https://propertysearch.ai/api/agents/gethireagentbyuser/${id}`,data);
    console.log("get my Agents response", response);
    return response.data;
  }
);



export const getOrderdetailOfAgent = createAsyncThunk("getOrderdetailOfLawyer", async ({id,data}) => {

  console.log("Agent payment body",id,data)
  const response = await axios(`https://propertysearch.ai/api/agents/getCallBack?order_id=${id}`,data)
  console.log("agent Payment  Response", response)
  return response.data

})


export const getAgentLeadList  = createAsyncThunk("getAgentLeadList", async ({id,data}) => {
  
  console.log("all Agent token",data)
  const response = await axios.get(`https://propertysearch.ai/api/agents/getagentleadlist/${id}`,data);
  console.log(" Agent lead list Response", response.data)
  return response.data.data
  
});



export const getAgentBookedProperties = createAsyncThunk("getAgentBookedProperties", async ({id,data}) => {
  console.log(" agent Booked prop token",data,id)
  const response = await axios.get(`https://propertysearch.ai/api/book/findbyagent/${id}`,data)
  console.log(" agent Booked Properties Response", response.data)
  return response.data.data
})


export const  getPropertyVisitors = createAsyncThunk("getPropertyVisitors", async ({userid,data}) => {
  console.log(" property visitors token",data,userid)
  const response = await axios.get(`https://propertysearch.ai/api/users/getvisitorlist/${userid}?page=${1}`,data)
  console.log("property visitors  Response", response.data)
  return response.data.data
})


export const  getPropertyVisitorsRequests = createAsyncThunk("getPropertyVisitorsRequests", async ({userid,data}) => {
  console.log(" property visitors request token",data,userid)
  const response = await axios.get(`https://propertysearch.ai/api/users/getvisitorrequest/${userid}?page=${1}`,data)
  console.log(" property visitors request Response", response.data)
  return response.data.data
})


const AgentSlice = createSlice({

  name: "AgentSlice",

  initialState: {

    requestedProperties: [],
    nearbyAgents: [],
    agentOrderDetails:{},
    AgentLeadList:[],
    AgentBookedProperties:[],
    PropertyVisitorsList : [],
    PropertyVisitorsRequestsList:[],
    myAgents: [],
    status: "idle",
    error: "",

  },


  extraReducers(builder) {
    // get Assigned Properties

    builder
      .addCase(getRequestedProperties.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getRequestedProperties.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.requestedProperties = action.payload;
      })
      .addCase(getRequestedProperties.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // get near by agents

    builder
      .addCase(getNearbyAgents.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getNearbyAgents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.nearbyAgents = action.payload;
      })
      .addCase(getNearbyAgents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // get My agents

    builder
      .addCase(getMyAgents.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getMyAgents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.myAgents = action.payload;
      })
      .addCase(getMyAgents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

      //agent order details

      builder
        .addCase(getOrderdetailOfAgent.pending, (state) => {
          state.status = "loading";
        })
        .addCase(getOrderdetailOfAgent.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.agentOrderDetails = action.payload;
        })
        .addCase(getOrderdetailOfAgent.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });


         // get Agents lead list

     builder
     .addCase(getAgentLeadList.pending, (state, action) => {
       state.status = "loading";
     })
     .addCase(getAgentLeadList.fulfilled, (state, action) => {
       state.status = "succeeded";
       state.AgentLeadList = action.payload;
     })
     .addCase(getAgentLeadList.rejected, (state, action) => {
       state.status = "failed";
       state.error = action.error.message;
     });


      // get  Booked  Property   by agent
       
      builder.addCase(getAgentBookedProperties.pending, (state, action) => {
        state.status = "loading"
    })
    .addCase(getAgentBookedProperties.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.AgentBookedProperties = action.payload
    })
    .addCase(getAgentBookedProperties.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
    })



      // get  Visitors list 
       
    builder.addCase(getPropertyVisitors.pending, (state, action) => {
        state.status = "loading"
    })
    .addCase(getPropertyVisitors.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.PropertyVisitorsList = action.payload
    })
    .addCase(getPropertyVisitors.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
    })
 

      // get Property Visitors Requests list 
       
      builder.addCase(getPropertyVisitorsRequests.pending, (state, action) => {
        state.status = "loading"
    })
    .addCase(getPropertyVisitorsRequests.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.PropertyVisitorsRequestsList = action.payload
    })
    .addCase(getPropertyVisitorsRequests.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
    })
 

  },
});

export default AgentSlice.reducer;
