import React, { useEffect } from "react";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as GiIcons from "react-icons/gi";
import { useState } from "react";
import { getNearbyProperties } from "../../Redux/Slices/PropertySlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { usePagination } from "../../hooks/pagination";

function NearbyProperties({ filteredProperties }) {

  console.log("all filtered Properties", filteredProperties);
  const [favorite, setFavorite] = useState([]);
  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex,
    displayPage,
  ] = usePagination(filteredProperties.length, filteredProperties.length);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useCurrentLocation = sessionStorage.getItem("userLocation");
  const location = JSON.parse(useCurrentLocation);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
 
  console.log("filteredProperties from nearby favorite", filteredProperties);
  
  const redirect = (id) => {
    navigate(`/ViewPropertyDetails/${id}`);
  };

  const addFavorite = (id) => {
    console.log("favorite", id);
    setFavorite([...favorite, id]);
  };


  useEffect(() => {
    
    const data = {
      method: "POST",
      body: JSON.stringify({
        userid: userId,
        propertyid: favorite,
      }),

      headers: {
        "Content-type": "application/json",
        authorization: token,
      },
    };

    console.log("send favorite body", data);

    fetch(`  https://propertysearch.ai/api/property/markasfavourite`, data)
      .then((response) => response.json())
      .then((data) => {
        console.log("Property mark as favorite", data);
        if (data.status == true) {
          const userData = {
            headers: {
              authorization: token,
            },
          };
          dispatch(
            getNearbyProperties({
              param1: location?.latitude,
              param2: location?.longitude,
              param3: userData,
              param4: userId,
            })
          );
        }
      })
      .catch((err) => console.log(err));
  }, [favorite]);

  return (
    <div class="column-content col-sm-5 col-xxl-3 mb-6 mt-4">
      {filteredProperties.length > 0 &&
        (() => {
          const displayPosts = [];
          for (let i = startPageIndex; i <= endPageIndex; i++) {
            displayPosts.push(
              <div className="content custom-scrollbar">
                <div
                  class="py-5 px-4 border rounded-lg shadow-hover-1 bg-white mb-4"
                  data-animate="fadeInUp"
                >
                  <div class="media flex-column flex-sm-row no-gutters">
                    <div class="col-sm-3 mr-sm-5 card border-0 hover-change-image bg-hover-overlay mb-sm-5">
                      <img
                        alt={`Property ${filteredProperties[i]?.propertydata?.id}`} // Provide a unique alt text
                        src={filteredProperties[i]?.mediaData?.images}
                        // src={ "/root/proptech_backend/images/property/20230829T064450702Z_rbupf.jpeg"}
                        style={{
                          width: "120.67px",
                          height: "100px",
                        }}
                      />
                    </div>
                    <div class="media-body mt-3 mt-sm-0">
                      <h2 class="my-0">
                        <a
                          href
                          onClick={() =>
                            redirect(filteredProperties[i]?.propertydata?.id)
                          }
                          class="fs-16 lh-2 text-dark hover-primary d-block"
                        >
                          {filteredProperties[i]?.propertydata?.title}
                        </a>
                      </h2>
                      {/* <p class="mb-1 font-weight-500 text-gray-light">Rating : {data?.rating}</p> */}
                      <p class="fs-17 font-weight-bold text-heading mb-1">
                        <span class="text-black-light">Price : </span>
                       ₹{filteredProperties[i]?.propertydata?.price}
                      </p>
                      <p class="mb-2 ml-0">
                        {filteredProperties[i]?.locationData?.city}{" "}
                        {filteredProperties[i]?.locationData?.state}
                      </p>
                    </div>
                    <span
                      class="fs-25 mr-xl-2 mt-3 mt-sm-0"
                      // class="w-40px h-40 border rounded-circle d-inline-flex align-items-center justify-content-center text-heading bg-white border-white bg-hover-primary border-hover-primary hover-white"
                    >
                     {filteredProperties[i]?.propertydata?.listedIn == "Rent" ? (
                      <span class="badge badge-primary mr-xl-2 mt-3 mt-sm-0">
                        For {filteredProperties[i]?.propertydata?.listedin}
                      </span>
                    ) : (
                      <span class="badge badge-primary mr-xl-2 mt-3 mt-sm-0">
                        For {filteredProperties[i]?.propertydata?.listedin}
                      </span>
                    )}
                    </span>
                  </div>
                  <div class="d-sm-flex justify-content-sm-between">
                    <ul class="list-inline d-flex mb-0 flex-wrap">
                      <li
                        class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip"
                        title="3 Bedroom"
                      >
                        <svg class="icon icon-bedroom fs-18 text-primary mr-1">
                          <BiIcons.BiBed />
                        </svg>
                        {filteredProperties[i]?.detailsData?.bedrooms}
                      </li>
                      <li
                        class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip"
                        title="3 Bathrooms"
                      >
                        <svg class="icon icon-shower fs-18 text-primary mr-1">
                          <FaIcons.FaShower />
                        </svg>
                        {filteredProperties[i]?.detailsData?.bathrooms}
                      </li>
                      <li
                        class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip"
                        title="Size"
                      >
                        <svg class="icon icon-square fs-18 text-primary mr-1">
                          <BiIcons.BiBookContent />
                        </svg>
                        {filteredProperties[i]?.detailsData?.sizeInFt} sqft
                      </li>
                      <li
                        class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                        data-toggle="tooltip"
                        title="1 Garage"
                      >
                        <svg class="icon icon-Garage fs-18 text-primary mr-1">
                          <GiIcons.GiHomeGarage />
                        </svg>
                        {filteredProperties[i]?.detailsData?.garages}Gr
                      </li>

                      {/* <li class="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                          data-toggle="tooltip" title="Year">
                                          <svg class="icon icon-year fs-18 text-primary mr-1">
                                              <use xlink:href="#icon-year"></use>
                                          </svg>
                                          2020
                                        </li> */}
                    </ul>
                    {/* <span class="badge badge-primary mr-xl-2 mt-3 mt-sm-0">For {data?.listedIn}</span> */}
                    
                  </div>
                </div>
              </div>
            );
          }
          return displayPosts;
        })()}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Pagination
          color="primary"
          count={totalPages}
          onChange={(event, value) => displayPage(value)}
        />
      </div>
    </div>
  );
}

export default NearbyProperties;
