import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getOrderdetail = createAsyncThunk("getOrderdetail", async ({id,data}) => {
    console.log("payment body",id,data)
    const response = await axios(`https://propertysearch.ai/api/contact/getCallBack?order_id=${id}`,data)
    console.log("Payment  Response", response)
    return response.data
})


export const getOwnerDetails = createAsyncThunk("getOwnerDetails", async ({id,data,userId}) => {
    console.log("OwnerDetails body",id,userId,data)
    const response = await axios(`https://propertysearch.ai/api/agents/propertydetails/${id}/${userId}`,data)
    console.log("OwnerDetails  Response", response)
    return response.data
})


export const getInvoiceList = createAsyncThunk("getInvoiceList", async ({id,data}) => {
    console.log("getInvoiceList body",id,data)
    const response = await axios(`https://propertysearch.ai/api/contact/getpaymenthistory/${id}`,data)
    console.log("getInvoiceList  Response", response.data)
    return response.data
}) 

 
export const getInvoiceDetails = createAsyncThunk("getInvoiceDetails", async ({id,data}) => {
    console.log("getInvoiceDetails body",id)
    const response = await axios(`https://propertysearch.ai/api/contact/invoicedetails/${id}`,data)
    console.log("getInvoiceDetails  Response", response)
    return response.data
}) 


export const getPaymentStatus = createAsyncThunk("getPaymentStatus", async ({id,propertyid,data}) => {
    console.log("getPaymentStatus body",id,propertyid,data)
    const response = await axios(`https://propertysearch.ai/api/contact/checkpaymentstatus/${propertyid}/${id}`,data)
    console.log("getPaymentStatus  Response", response.data)
    return response.data
}) 


export const createBookingOrder = createAsyncThunk("createBookingOrder", async (orderData) => {
    console.log("createBookingOrder body",orderData)
    const response = await fetch(`https://propertysearch.ai/api/contact/create`,orderData)
    console.log("createBookingOrder  Response", response)
    return response.data
}) 


const PaymentSlice = createSlice({
   
    name: "PaymentSlice",

    initialState: {
        orderResponse : {},
        ownerDetails : {},
        paymentStatus: null,
        invoiceList:[],
        invoiceDetails:{},
        bookingtStatus:{},
        status : "idle",
        error : ""
    },

    extraReducers(builder) {

        // get  agent request 
       
        builder.addCase(getOrderdetail.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getOrderdetail.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.orderResponse = action.payload
            })
            .addCase(getOrderdetail.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })

        // get  owner details 
       
        builder.addCase(getOwnerDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getOwnerDetails.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.ownerDetails = action.payload
        })
        .addCase(getOwnerDetails.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })


         // get Invoice List
       
         builder.addCase(getInvoiceList.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getInvoiceList.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.invoiceList = action.payload
        })
        .addCase(getInvoiceList.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })

          // get Invoice Details
       
          builder.addCase(getInvoiceDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getInvoiceDetails.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.invoiceDetails = action.payload
        })
        .addCase(getInvoiceDetails.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })

           // get Payment status
       
           builder.addCase(getPaymentStatus.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getPaymentStatus.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.paymentStatus = action.payload
        })
        .addCase(getPaymentStatus.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })


          // Create Booking Order
       
          builder.addCase(createBookingOrder.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(createBookingOrder.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.bookingtStatus = action.payload
        })
        .addCase(createBookingOrder.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })
     }
  });
  
  export default PaymentSlice.reducer;