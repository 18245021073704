import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



export const getBankList = createAsyncThunk("getBankList", async({data}) => {
    const response = await axios.get('http://localhost:8090/api/banker/alldomains',data)
    console.log('Banks List in Redux :', response)
    return response.data
})


export const getBankerLeadList = createAsyncThunk('getBankerLeadList',async({bankerId,data}) =>{
    console.log('Banker id,data :', bankerId,data)
    const response = await axios.get(`https://propertysearch.ai/api/bankerlead/getbankerlead/${bankerId}`,data)
    console.log('banker lead list response :', response)
} )




const BankerSlice = createSlice({
    name : 'BankerSlice',
    initialState : {
        bankList : [],
        bankerLeadList: [],
        status :'idle',
        error : ''
    },

    extraReducers(builder){

        builder.addCase(getBankList.pending, (state,action) => {
            state.status = 'loading'
        }).addCase(getBankList.fulfilled, (state,action) => {
            state.status = 'succeeded'
            state.bankList = action.payload 
        }).addCase(getBankList.rejected,(state,action) => {
            state.status = 'failed'
            state.error = action.error.message
        })


        builder.addCase(getBankerLeadList.pending, (state,action) =>{
            state.status = 'loading'
        }).addCase(getBankerLeadList.fulfilled, (state,action) => {
            state.status = 'succeeded'
            state.bankerLeadList = action.payload
        }).addCase(getBankerLeadList.rejected, (state,action) => {
            state.status = 'failed'
            state.error = action.error.message 
        }) 

    }
})


export default BankerSlice.reducer;

