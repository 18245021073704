import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getShortlistedProperties = createAsyncThunk("getShortlistedProperties", async ({id,data}) => {
    console.log(" Shortlisted token",data,id)
    const response = await axios.get(`https://propertysearch.ai/api/property/findbyshortlisted/${id}`,data)
    console.log(" Shortlisted Properties Response", response.data)
    return response.data.data
})


export const getInterestedProperties = createAsyncThunk("getInterestedProperties", async ({id,data}) => {
    console.log(" Interested token",data,id)
    const response = await axios.get(`https://propertysearch.ai/api/method/intrestedlist/${id}`,data)
    console.log(" Interested Properties Response", response.data)
    return response.data.data
})


export const getBookedProperties = createAsyncThunk("getBookedProperties", async ({id,data}) => {
    console.log(" Booked prop token",data,id)
    const response = await axios.get(`https://propertysearch.ai/api/book/findbyuser/${id}`,data)
    console.log(" Booked Properties Response", response.data)
    return response.data.data
})


export const getBidProperties = createAsyncThunk("getBidProperties", async ({id,data}) => {
    console.log("Bid prop token",data,id)
    const response = await axios.get(`https://propertysearch.ai/api/bid/findbyuserid/${id}`,data)
    console.log(" Bid Properties Response", response.data)
    return response.data.data
})



export const getMeetingRecordsHistory = createAsyncThunk("getMeetingRecordsHistory", async ({id,userId,data}) => {
    console.log(" MeetingRecordsHistory prop token",data,id)
    const response = await axios.get(`https://propertysearch.ai/api/meeting/getmeetingrecord/${id}/${userId}`,data)
    console.log(" MeetingRecordsHistory Response", response.data)
    return response.data
})


export const getAgreementFromLawyer = createAsyncThunk("getAgreementFromLawyer", async ({id,userId,data}) => {

    console.log(" get Agreement FromLawyer prop token",data,id)
    const response = await axios.get(` https://propertysearch.ai/api/lawyer/getbuyeragreement/${userId}/${id}`,data)
    console.log(" get Agreement FromLawyer Response", response.data.data)
    return response.data.data

})


export const getPennyTransactionHistory = createAsyncThunk("getPennyTransactionHistory", async ({userId,data}) => {

    console.log(" get PennyTransaction History token",userId,data)
    const response = await axios.get(`https://propertysearch.ai/api/book/getpannytransaction/${userId}`,data)
    console.log(" get Penny TransactionHistory Response", response.data.data)
    return response.data.data

})


export const getBookingTransactionHistory = createAsyncThunk("getBookingTransactionHistory", async ({userId,data}) => {

    console.log(" get BookingTransactionHistory token",userId,data)
    const response = await axios.get(`https://propertysearch.ai/api/book/getbookingtransaction/${userId}`,data)
    console.log("get BookingTransactionHistory Response", response.data.data)
    return response.data.data

})


export const getAdminBankDetails = createAsyncThunk("getAdminBankDetails", async (userdata) => {

    console.log(" get AdminBankDetails History token",userdata)
    const response = await axios.get(`https://propertysearch.ai/api/profile/findadminbankdetails/1`,userdata)
    console.log(" get AdminBankDetails Response", response)
    return response.data.data

})


export const getBookedPropertyPaymentsDetails = createAsyncThunk("getBookedPropertyPaymentsDetails", async ({id,data,UserId}) => {
    console.log("  Booked prop token",data,UserId,id)
    const response = await axios.get(`https://propertysearch.ai/api/property/getbookpropertydetails/${id}/${UserId}`,data)
    console.log("  Booked Property payment details Response", response.data)
    return response.data.data
})



export const getPaidBookedPropertyEMIlist = createAsyncThunk("getPaidBookedPropertyEMIlist", async ({id,data,UserId}) => {
    console.log("  Booked prop token",data,id.UserId)
    const response = await axios.get(`https://propertysearch.ai/api/propertypayment/emipaidhistorybybuyer/${id}/${UserId}`,data)
    console.log("  Booked Property EMI Response", response.data)
    return response.data.data
})



export const getPaymentWithdrawRequest = createAsyncThunk("getPaymentWithdrawRequest", async ({propId,data,UserId}) => {
    console.log("  Payment WithdrawRequest token",propId,data,UserId)
    const response = await axios.get(`https://propertysearch.ai/api/propertypayment/getwithdrawalhistorybybuyer/${propId}/${UserId}`,data)
    console.log("  Payment WithdrawRequest Response", response.data)
    return response.data.data
})


export const getOtpForPaymentrelease = createAsyncThunk("getOtpForPaymentrelease", async ({data,id}) => {
    console.log(" getOtp ForPaymentrelease token",id,data)
    const response = await axios.get(`https://propertysearch.ai/api/propertypayment/sendotp/${id}`,data)
    console.log("  getOtp ForPaymentrelease Response", response.data)
    return response.data
})

  
 export const getAgentPaymentreleaseRequestforBuyer = createAsyncThunk("getAgentPaymentreleaseRequestforBuyer", async ({data,UserId}) => {
    console.log(" owner Booked prop token",data,UserId)
    const response = await axios.get(`https://propertysearch.ai/api/agentrelease/getagentrequest/${UserId}`,data)
    console.log(" getAgentPaymentreleaseRequestforBuyer", response.data)
    return response.data.data
  })
  

const BuyerSlice = createSlice({

    name: "BuyerSlice",

    initialState: {
        
        Shortlisted : [],
        InterestedProperties : [],
        AgentPaymentreleaseRequest:{},
        MeetingHistory:[],
        PaymentReleaseOtp:{},
        PennyTransactionHistory:[],
        BookingTransactionHistory:[],
        BookedPropertyPymentsDetails:{},
        PaymentWithdrawRequestList:[],
        PaidBookedPropertyEMIlist:[],
        BookedProperties:[],
        BidProperties:[],
        agreementFromLawyer:{},
        AdminBankAccountDetails:{},
        status : "idle",
        error : ""

    },


    extraReducers(builder) {

        // get  Shortlisted  Property 
       
        builder.addCase(getShortlistedProperties.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getShortlistedProperties.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.Shortlisted = action.payload
            })
            .addCase(getShortlistedProperties.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


            //get booked property payments details

               
      builder.addCase(getBookedPropertyPaymentsDetails.pending, (state, action) => {
        state.status = "loading"
    })
    .addCase(getBookedPropertyPaymentsDetails.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.BookedPropertyPymentsDetails = action.payload
    })
    .addCase(getBookedPropertyPaymentsDetails.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
    })



             // get  Interested  Property 
       
        builder.addCase(getInterestedProperties.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getInterestedProperties.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.InterestedProperties = action.payload
        })
        .addCase(getInterestedProperties.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })

           // get  PaymentRelease Otp
       
           builder.addCase(getOtpForPaymentrelease.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getOtpForPaymentrelease.fulfilled, (state, action) => {
            state.status = "succeeded"
            state.PaymentReleaseOtp = action.payload
        })
        .addCase(getOtpForPaymentrelease.rejected, (state, action) => {
            state.status = "failed"
            state.error = action.error.message
        })


         // get agent payment release request

    builder
    .addCase(getAgentPaymentreleaseRequestforBuyer.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getAgentPaymentreleaseRequestforBuyer.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.AgentPaymentreleaseRequest = action.payload;
    })
    .addCase(getAgentPaymentreleaseRequestforBuyer.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });




            // get  Booked  Property 
       
            builder.addCase(getBookedProperties.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getBookedProperties.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.BookedProperties = action.payload
            })
            .addCase(getBookedProperties.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


              // get  Booked  Property 
       
              builder.addCase(getPaymentWithdrawRequest.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getPaymentWithdrawRequest.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.PaymentWithdrawRequestList = action.payload
            })
            .addCase(getPaymentWithdrawRequest.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


             // get  Booked  Property  EMI list
       
             builder.addCase(getPaidBookedPropertyEMIlist.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getPaidBookedPropertyEMIlist.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.PaidBookedPropertyEMIlist = action.payload
            })
            .addCase(getPaidBookedPropertyEMIlist.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })

             // get  bid  Property 
       
             builder.addCase(getBidProperties.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getBidProperties.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.BidProperties = action.payload
            })
            .addCase(getBidProperties.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


              // get  Meeting records history   
       
              builder.addCase(getMeetingRecordsHistory.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getMeetingRecordsHistory.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.MeetingHistory = action.payload
            })
            .addCase(getMeetingRecordsHistory.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


              // get  Agreement from lawyer   
       
              builder.addCase(getAgreementFromLawyer.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getAgreementFromLawyer.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.agreementFromLawyer = action.payload
            })
            .addCase(getAgreementFromLawyer.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })

              // get  Penny Transaction history   
       
             builder.addCase(getPennyTransactionHistory.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getPennyTransactionHistory.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.PennyTransactionHistory = action.payload
            })
            .addCase(getPennyTransactionHistory.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


            
              // get  booking Transaction history   
       
              builder.addCase(getBookingTransactionHistory.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getBookingTransactionHistory.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.BookingTransactionHistory = action.payload
            })
            .addCase(getBookingTransactionHistory.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


             // get  Admin Account details
       
             builder.addCase(getAdminBankDetails.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getAdminBankDetails.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.AdminBankAccountDetails = action.payload
            })
            .addCase(getAdminBankDetails.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
     }
  });
  
  export default BuyerSlice.reducer;