import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getLawyerCases = createAsyncThunk("getLawyerCases", async ({id,data}) => {
  
    console.log("Lawyer Properties  ",id,data)
    const response = await axios.get(`https://propertysearch.ai/api/lawyer/getleaddetails/${id}`,data);
    console.log(" Lawyer Properties Response", response.data.data)
    return response.data.data 
  
});


export const getAllLawyer  = createAsyncThunk("getAllLawyer", async ({id,data}) => {
  
  console.log("all Lawyer token",id,data)
  const response = await axios.get(`https://propertysearch.ai/api/lawyer/alllawyers/${id}`,data);
  console.log(" Lawyer list Response :", response.data)
  return response.data.data
  
});


export const getMyLawyer = createAsyncThunk("getMyLawyer", async ({id , data}) => {
  console.log("get my lawyer ",id , data)
  const response = await axios.get(`https://propertysearch.ai/api/lawyer/getallassignlawyer/${id}`,data)
  console.log("get my lawyer response", response.data)
  return response.data.data
})


export const getLawyerLeadList  = createAsyncThunk("getLawyerLeadList", async ({id,data}) => {
  
  console.log("all Lawyerlead token",data)
  const response = await axios.get(`https://propertysearch.ai/api/lawyer/leadlist/${id}`,data);
  console.log(" Lawyer lead list Response", response.data)
  return response.data.data
});



export const getOrderdetailOfLawyer = createAsyncThunk("getOrderdetailOfLawyer", async ({id,data}) => {
  console.log("lawyer payment body",id,data)
  const response = await axios(`https://propertysearch.ai/api/lawyer/getCallBack?order_id=${id}`,data)
  console.log("lawyer Payment  Response", response.data)
  return response.data
})




const LawyerSlice = createSlice({

  name: "LawyerSlice",
  initialState: {
    cases: [],
    lawyerList: [],
    lawyerLeadList: [],
    myLawyer:[],
    lawyerOrderDetails:{},
    status: "idle",
    error: null,
  },


  extraReducers(builder) {

    builder
      .addCase(getLawyerCases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLawyerCases.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cases = action.payload;
      })
      .addCase(getLawyerCases.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
       
      //Lawyer list

      builder
      .addCase(getAllLawyer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllLawyer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.lawyerList = action.payload;
      })
      .addCase(getAllLawyer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });


       //Lawyer Lead list

       builder
       .addCase(getLawyerLeadList.pending, (state) => {
         state.status = "loading";
       })
       .addCase(getLawyerLeadList.fulfilled, (state, action) => {
         state.status = "succeeded";
         state.lawyerLeadList = action.payload;
       })
       .addCase(getLawyerLeadList.rejected, (state, action) => {
         state.status = "failed";
         state.error = action.error.message;
       });


        //Lawyer order details

        builder
        .addCase(getOrderdetailOfLawyer.pending, (state) => {
          state.status = "loading";
        })
        .addCase(getOrderdetailOfLawyer.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.lawyerOrderDetails = action.payload;
        })
        .addCase(getOrderdetailOfLawyer.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
 

         // get My Lawyer 

         builder.addCase(getMyLawyer.pending, (state, action) => {
          state.status = "loading"
      })
      .addCase(getMyLawyer.fulfilled, (state, action) => {
          state.status = "succeeded"
          state.myLawyer = action.payload
      })
      .addCase(getMyLawyer.rejected, (state, action) => {
          state.status = "failed"
          state.error = action.error.message
      })
  },
});

export default LawyerSlice.reducer;
