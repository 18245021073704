import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAgentRequest = createAsyncThunk(
  "getAgentRequest",
  async ({ id, data }) => {
    console.log("token", data, id);
    const response = await axios.get(
      `https://propertysearch.ai/api/agents/intrestedagent/${id}`,
      data
    );
    console.log("Agent Request Properties Response", response);
    return response.data;
  }
);


export const getAgentRequestDetails = createAsyncThunk(
  "getAgentRequestDetails",
  async ({ id, agentId, data,UserId }) => {
    console.log("get agent details param", agentId, id, data);
    const response = await axios.get(
      `https://propertysearch.ai/api/agents/holdpropertybyagent/${agentId}/${UserId}`,
      data 
    );
    console.log("Agent Request details Response", response.data);
    return response.data;
  }
);



export const getBidingRequest = createAsyncThunk(
  "getBidingRequest",
  async ({ id, data }) => {
    console.log("get BidingRequest param", data, id);
    const response = await axios.get(
      `https://propertysearch.ai/api/bid/findbypropertyid/${id}`,
      data
    );
    console.log("getBiding Request  Response", response);
    return response.data.data;
  }
);


export const acceptBidingRequest = createAsyncThunk(
  "acceptBidingRequest",
  async ({ id, data }) => {
    console.log("get acceptBidingRequest param", data, id);
    const response = await axios(
      `https://propertysearch.ai/api/bid/accept/${id}`,
      data
    );
    console.log("acceptBidingRequest Request  Response", response);
    return response.data;
  }
);


export const rejectBidingRequest = createAsyncThunk(
  "rejectBidingRequest",
  async ({ id, data }) => {
    console.log("get rejectBidingRequest param", data, id);
    const response = await axios(
      `https://propertysearch.ai/api/bid/reject/${id}`,
      data
    );
    console.log("rejectBidingRequest Request  Response", response);
    return response.data;
  }
);


export const getBookingRequest = createAsyncThunk(
  "getBookingRequest",
  async ({ id, data }) => {
    console.log("get BookingRequest param", data, id);
    const response = await axios.get(
      `https://propertysearch.ai/api/book/findbypropertyid/${id}`,
      data
    );
    console.log("Booking Request  Response", response.data.data);
    return response.data.data
  }
);

  
export const getContactRequest = createAsyncThunk(
  "getContactRequest",
  async ({ userId,id, data }) => {
    console.log("get ContactRequest  param", data, id);
    const response = await axios.get(
      `https://propertysearch.ai/api/method/findbyseller/${userId}/${id}`,
      data
    );
    console.log("Contact  Request  Response", response.data.Data);
    return response.data.Data
  }
);


export const getLawyerRequest = createAsyncThunk(
  "getLawyerRequest",
  async ({ id,userId, data }) => {
    console.log("get LawyerRequest  param", data, id);
    const response = await axios.get(
      `https://propertysearch.ai/api/lawyer/leadlistbyseller/${userId}/${id}`,
      data
    );
    console.log("Lawyer Request  Response", response.data.data);
    return response.data.data;
  }
);


export const getFilteredByRequestCount = createAsyncThunk(
  "getFilteredByRequestCount",
  async ({ id, data }) => {
    console.log("get ContactRequest  param", data, id);
    const response = await axios.get(
      `https://propertysearch.ai/api/property/findbyrequestcount/${id}`,
      data
    );
    console.log("  Request  count Response", response.data);
    return response.data;
  }
);



export const getAgreementFromLawyer = createAsyncThunk(
  "getAgreementFromLawyer",
  async ({ id, userId, data }) => {
    console.log(" get Agreement FromLawyer prop token", data, id,userId);
    const response = await axios.get(
      `https://propertysearch.ai/api/lawyer/getselleragreement/${userId}/${id}`,
      data
    )
    console.log(" get Agreement FromLawyer Response", response.data.data);
    return response.data.data;
  }
);


export const getLawyerRequestDetails  = createAsyncThunk("getLawyerRequestDetails", async ({id,data}) => {
  
  console.log("get LawyerRequestDetails token",data)
  const response = await axios.get(`https://propertysearch.ai/api/lawyer/getleaddetailsbyseller/${id}`,data);
  console.log(" get LawyerRequestDetails Response", response.data.data)
  return response.data.data
  
});


export const getUserVerifiedProperty  = createAsyncThunk("getUserVerifiedProperty", async ({id,data}) => {
  
  console.log("get UserVerifiedProperty token",data)
  const response = await axios.get(`https://propertysearch.ai/api/property/myverifiedproperty/${id}`,data);
  console.log(" get UserVerifiedProperty Response", response.data)
  return response.data
  
});


export const getRecievedPayments = createAsyncThunk("getRecievedPayments", async ({UserId,data}) => {
  console.log(" Recieved Payments prop token",data,UserId)
  const response = await axios.get(`https://propertysearch.ai/api/propertypayment/getpaymnethistory/3/4`,data)
  console.log(" Recieved Payments Response", response.data.data)
  return response.data.data
})


export const getOwnerBookedProperties = createAsyncThunk("getOwnerBookedProperties", async ({id,data}) => {
  console.log(" owner Booked prop token",data,id)
  const response = await axios.get(`https://propertysearch.ai/api/property/getbookproperty/${id}`,data)
  console.log(" owner Booked Properties Response", response.data)
  return response.data.data 
})


export const getOwnerBookedPropertyDetails = createAsyncThunk("getOwnerBookedPropertyDetails", async ({id,data,UserId}) => {
  console.log(" owner Booked prop token",data,id)
  const response = await axios.get(`https://propertysearch.ai/api/property/getbookpropertydetails/${id}/${UserId}`,data)
  console.log(" owner Booked Property details Response", response.data)
  return response.data.data
})


export const getAgentPaymentreleaseRequestforSeller = createAsyncThunk("getAgentPaymentreleaseRequestforSeller", async ({data,UserId}) => {
  console.log(" owner Booked prop token",data,UserId)
  const response = await axios.get(`https://propertysearch.ai/api/agentrelease/getrequestbyseller/${UserId}`,data)
  console.log(" getAgentPaymentreleaseRequestforSeller", response.data)
  return response.data.data
})


const SellerSlice = createSlice({
  name: "SellerSlice",

  initialState: {
    AgentRequest: [],
    AgentRequestDetails: {},
    AgentPaymentreleaseRequest:{},
    BidingRequest: [],
    BookingRequest: [],
    ContactRequest: [],
    LawyerRequest:[],
    OwnerBookedProperties:[],
    RecievedPayments:[],
    OwnerBookedPropertyDetails:{},
    UserVerifiedProperty:[],
    LawyerRequestDetails:{},
    agreementFromLawyer: {},
    AcceptRequest: {},
    RejectRequest: {},
    filteredByRequestCount: null,
    status: "idle",
    error: "",
  },

  extraReducers(builder) {
    // get  agent request

    builder
      // getFilteredByRequestCount
      .addCase(getFilteredByRequestCount.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getFilteredByRequestCount.fulfilled, (state, action) => {
        console.log("payload agent request", action.payload);
        state.status = "succeeded";
        state.filteredByRequestCount = action.payload;
      })
      .addCase(getFilteredByRequestCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAgentRequest.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAgentRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.AgentRequest = action.payload;
      })
      .addCase(getAgentRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // get agent request details

    builder
      .addCase(getAgentRequestDetails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAgentRequestDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.AgentRequestDetails = action.payload;
      })
      .addCase(getAgentRequestDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });


   // get agent payment release request

    builder
    .addCase(getAgentPaymentreleaseRequestforSeller.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getAgentPaymentreleaseRequestforSeller.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.AgentPaymentreleaseRequest = action.payload;
    })
    .addCase(getAgentPaymentreleaseRequestforSeller.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });


       // get recieved payments

    builder
    .addCase(getRecievedPayments.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getRecievedPayments.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.RecievedPayments = action.payload;
    })
    .addCase(getRecievedPayments.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });


      // get  owner  Booked  Property 
       
      builder.addCase(getOwnerBookedProperties.pending, (state, action) => {
        state.status = "loading"
    })
    .addCase(getOwnerBookedProperties.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.OwnerBookedProperties = action.payload
    })
    .addCase(getOwnerBookedProperties.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
    })


      // get  owner  Booked  Property details
       
      builder.addCase(getOwnerBookedPropertyDetails.pending, (state, action) => {
        state.status = "loading"
    })
    .addCase(getOwnerBookedPropertyDetails.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.OwnerBookedPropertyDetails = action.payload
    })
    .addCase(getOwnerBookedPropertyDetails.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
    })


    // Biding  request List

    builder
      .addCase(getBidingRequest.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getBidingRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.BidingRequest = action.payload;
      })
      .addCase(getBidingRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // Booking  request List

    builder
      .addCase(getBookingRequest.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getBookingRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.BookingRequest = action.payload;
      })
      .addCase(getBookingRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // Contact  request List

    builder
      .addCase(getContactRequest.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getContactRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.ContactRequest = action.payload;
      })
      .addCase(getContactRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

      // Agent  request List

    builder
    .addCase(getLawyerRequest.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getLawyerRequest.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.LawyerRequest = action.payload;
    })
    .addCase(getLawyerRequest.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });

    //  accept  request List

    builder
      .addCase(acceptBidingRequest.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(acceptBidingRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.AcceptRequest = action.payload;
      })
      .addCase(acceptBidingRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    //  reject  request List

    builder
      .addCase(rejectBidingRequest.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(rejectBidingRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.RejectRequest = action.payload;
      })
      .addCase(rejectBidingRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // get  Agreement from lawyer

    builder
      .addCase(getAgreementFromLawyer.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAgreementFromLawyer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.agreementFromLawyer = action.payload;
      })
      .addCase(getAgreementFromLawyer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });


       // get  layer request details 

    builder
    .addCase(getLawyerRequestDetails.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getLawyerRequestDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.LawyerRequestDetails = action.payload;
    })
    .addCase(getLawyerRequestDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });


      // get  user verified property

      builder
      .addCase(getUserVerifiedProperty.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserVerifiedProperty.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.UserVerifiedProperty = action.payload;
      })
      .addCase(getUserVerifiedProperty.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default SellerSlice.reducer;
