import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Price Range
export const getPriceRange = createAsyncThunk("getPriceRange", async () => {
  const response = await axios.get("https://propertysearch.ai/pythonBiApi/Range");
  return response.data;
});

//Filter Options
export const getFilterOptions = createAsyncThunk(

  "getFilterOptions",
  async (option) => {
   
    let response

      try {
        response = await axios.get(`https://propertysearch.ai/pythonBiApi/${option}`);
      
      } catch (error) {
        console.log("error in option list", error);
      }


    if (response.data instanceof Array) {
      console.log("lisst not rendering", response?.data);
      let key = option.toLowerCase();
      return { key: key, list: response?.data };
    }
  }

  
);

// List of properties using filter

export const getPropertyList = createAsyncThunk(
  "getPropetylist",
  async (url) => {
    console.log(" I am url here", url);
    try {
      const response = await axios.get(url);
      console.log("responsenotupdating", response.data);
      if (response.status !== 200) {
        return [];
      }
      if (response.status === 200) {
        console.log("I am esponse for propert list", response.data);
        return response.data;
      } else {
        return;
      }
    } catch (error) {
      console.log("I am esponse for propert list error", error);
      return [];

      // Throw the error to be handled in the Redux thunk
    }
  }
);

//Property details using ID
export const getPropertyDetails = createAsyncThunk(
  "getPropertyDetails",
  async (userID) => {
    let response, responseChart;
    try {
      response = await axios.get(`https://propertysearch.ai/pythonBiApi/Details/${userID}`);
      // responseChart = await axios.get(
      //   `https://propertysearch.ai/pythonBiApi/get_values/${userID}`
      // );
    } catch (error) {
      console.log("getPropertyDetailserror", error);
    }
    console.log("i am the repsonde ", userID);

    console.log("i am response.data, chartss", response.data);

    return { details: response.data, chart: "responseChart.data" };
  }
);

//PieChart details using ID
export const getBarChartDetails = createAsyncThunk(
  "getPieChartDetails",
  async () => {
    const response = await axios.get(`https://propertysearch.ai/pythonBiApi/CategoryCount`);
    console.log(response.data, "i am responsedatatatatat");
    return response.data;
  }
);


// Define the initial state for the slice

const initialState = {
  appliedFilters: {
    country: null,
    city: null,
    state: null,
    bedrooms: null,
    bathrooms: null,
    floorsNo: null,
    category: null,
    subcategory: null,
    exteriorMaterial: null,
    structureType: null,
    min_price: null,
    max_price: null,
    possession: null,
  },

  barChartDetails: {},
  priceRangeWindow: false,
  appliedFilters2: [],
  properties: [],
  allpropertyDetails: [],
  propertyDetails1: { details: null, pieChart: null },
  propertyDetails2: { details: null, pieChart: null },
  range: { max_price: null, min_price: null },
  filterOptionList: [],
  filterOptionItemList: {

    country: { selected: null, list: [] },
    state: { selected: null, list: [] },
    city: { selected: null, list: [] },
    bedrooms: { selected: null, list: [] },
    bathrooms: { selected: null, list: [] },
    category: { selected: null, list: [] },
    subcategory: { selected: null, list: [] },
    min_price: { selected: null },
    max_price: { selected: null },
    possession: { selected: null, list: [] },

  },
  status: "",
  error: "",
  propertDetailsToggle: true,
};

const mapPropertySlice = createSlice({

  name: "property",
  initialState,

  reducers: {
    setPriceRangeWindow: (state) => {
      state.priceRangeWindow = !state.priceRangeWindow;
    },
    removeProperty: (state, action) => {
      const newPropertyListAfterDeletion = state.allpropertyDetails.filter(
        (property) => {
          // Customize this condition as per your object comparison logic
          return property.propertydetails_id !== action.payload;
        }
      );
      state.allpropertyDetails = newPropertyListAfterDeletion;
    },
    setParameter: (state, action) => {
      console.log("alaldkfjhdji", action.payload);
      const { key, list, value } = action.payload;

      if (
        value !== null &&
        value !== undefined &&
        value.toString().includes("Select")
      ) {
        state.appliedFilters[key] = null;
        state.filterOptionItemList[key].selected = null;
      } else {
        if (state.appliedFilters.hasOwnProperty(key)) {
          state.appliedFilters[key] = value;
          state.filterOptionItemList[key].selected = value;
          state.filterOptionItemList[key].list = list;
        } else {
          console.warn(`Invalid property key: ${key}`);
        }
      }
    },

    setParameter2: (state, action) => {
      console.log("action.payload setparameter", action.payload);
    },

  },
  extraReducers(builder) {
    // get property List
    builder
      .addCase(getPropertyList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPropertyList.fulfilled, (state, action) => {
        console.log(
          "action.payloadaction.payloadaction.payloadaction.payload",
          action.payload
        );
        state.status = "succeeded";
        if (action.payload instanceof Array) {
          state.properties = action.payload;
        } else {
          state.properties = [];
        }
      })
      .addCase(getPropertyList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // get property Details
      .addCase(getPropertyDetails.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(getPropertyDetails.fulfilled, (state, action) => {
        const { details, chart } = action.payload;
        state.status = "succeeded";
        const propertyDetailsToAdd = {
          ...details[0],
          chart,
        };

        console.log("all properties availabble", propertyDetailsToAdd);
        const isDuplicate = state.allpropertyDetails.some((property) => {
          // Customize this condition as per your object comparison logic
          return (
            property.propertydetails_id ===
            propertyDetailsToAdd.propertydetails_id
          );
        });

        if (!isDuplicate && state.allpropertyDetails.length < 3) {
          state.status = "succeeded";
          state.allpropertyDetails.push(propertyDetailsToAdd);
        }

        if (state.propertDetailsToggle) {
          state.propertyDetails1.details = details[0];
          state.propertyDetails1.pieChart = chart;
          state.propertDetailsToggle = false;
        } else {
          state.propertyDetails2.details = details[0];
          state.propertyDetails2.pieChart = chart;
          state.propertDetailsToggle = true;
        }
      })
      .addCase(getPropertyDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // get Property Chart Details
      .addCase(getBarChartDetails.fulfilled, (state, action) => {
        console.log("ActionPayloadbar", action.payload);
        state.status = "succeeded";
        state.barChartDetails = action.payload;
      })
      .addCase(getBarChartDetails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getBarChartDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //get Price Range
      .addCase(getPriceRange.fulfilled, (state, action) => {
        console.log("this my price range", action.payload);
        state.range = action.payload;
      })
      .addCase(getPriceRange.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPriceRange.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //get filter options
      .addCase(getFilterOptions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        if (action.payload) {
          const { key, list } = action.payload;
          state.status = "succeeded";
          state.filterOptionItemList[key].list = list;
        }
      })

      .addCase(getFilterOptions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    // .addCase(getPriceRange.rejected,(state,action)=>{
    //   state.status="failed";
    //   state.error=action.error.message;
    // })
    // .addCase(getPriceRange.pending, (state, action) => {
    //   state.status = "loading";
    // })
    // .addCase(getPriceRange.fulfilled, (state, action) => {
    //   console.log("this my price range", state.payload)
    //   state.status = action.payload;
    // })
  },
});

export const {
  setParameter,
  setParameter2,
  setPriceRangeWindow,
  removeProperty,
} = mapPropertySlice.actions;

export default mapPropertySlice.reducer;
